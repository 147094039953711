import * as React from "react"

import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

import "@fontsource/ubuntu/400.css"
import "@fontsource/ubuntu/700.css"

import "../styles/global.css"

import BottomBanner from "./BottomBanner"

/** This component wraps the whole application in App/Test/Storybook environments. Pass all global providers here and add global imports at the top of the file */
const RootWrapper = ({ children }) => {
	return (
		<>
			{children}
			<BottomBanner id="PART-OF-LRDS" />
		</>
	)
}

export default RootWrapper
