exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-mongodb-bloghanseaticsoft-articles-slug-js": () => import("./../../../src/pages/blog/{mongodbBloghanseaticsoftArticles.slug}.js" /* webpackChunkName: "component---src-pages-blog-mongodb-bloghanseaticsoft-articles-slug-js" */),
  "component---src-pages-certifications-js": () => import("./../../../src/pages/certifications.js" /* webpackChunkName: "component---src-pages-certifications-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-demo-index-js": () => import("./../../../src/pages/demo/index.js" /* webpackChunkName: "component---src-pages-demo-index-js" */),
  "component---src-pages-demo-thank-you-js": () => import("./../../../src/pages/demo/thank-you.js" /* webpackChunkName: "component---src-pages-demo-thank-you-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hanseaticsoft-js": () => import("./../../../src/pages/hanseaticsoft.js" /* webpackChunkName: "component---src-pages-hanseaticsoft-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-strapi-product-product-package-slug-strapi-product-slug-js": () => import("./../../../src/pages/{StrapiProduct.product_package__slug}/{StrapiProduct.slug}.js" /* webpackChunkName: "component---src-pages-strapi-product-product-package-slug-strapi-product-slug-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-wallpapers-js": () => import("./../../../src/pages/wallpapers.js" /* webpackChunkName: "component---src-pages-wallpapers-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-new-features-index-js": () => import("./../../../src/templates/new-features-index.js" /* webpackChunkName: "component---src-templates-new-features-index-js" */),
  "component---src-templates-press-index-js": () => import("./../../../src/templates/press-index.js" /* webpackChunkName: "component---src-templates-press-index-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/static-page.js" /* webpackChunkName: "component---src-templates-static-page-js" */)
}

