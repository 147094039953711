import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"

import { Button, TextButton } from "./Button"

const dropIn = {
	hidden: {
		y: "50px",
		opacity: 0,
	},
	visible: {
		y: "0",
		opacity: 1,
		transition: {
			delay: 5,
			type: "spring",
			stiffness: 100,
		},
	},
	exit: {
		y: "50px",
		opacity: 0,
		transition: {
			type: "spring",
			stiffness: 100,
		},
	},
}

const BottomBanner = ({ id, text, cta, ctaLink }) => {
	const [showBanner, setShowBanner] = useState(true)

	const localStorageKey = `SHOW_BOTTOM_BANNER-${id.toUpperCase()}`

	useEffect(() => {
		const data = window.localStorage.getItem(localStorageKey)
		if (data !== null) setShowBanner(JSON.parse(data))
	}, [])

	useEffect(() => {
		window.localStorage.setItem(localStorageKey, JSON.stringify(showBanner))
	}, [showBanner])

	return (
		<AnimatePresence>
			{showBanner && (
				<motion.div
					variants={dropIn}
					initial="hidden"
					animate="visible"
					exit="exit"
					className="flex fixed justify-center bottom-0 px-20 pb-20 z-50 w-full pointer-events-none">
					<div className="max-w-[600px] bg-[#021A32] rounded-medium shadow-xl px-30 py-30 lg:py-20 flex flex-col lg:flex-row lg:grow items-center pointer-events-auto">
						<div className="lg:grow text-white font-medium text-center lg:text-left">
							We’re now part of Lloyd's Register.
						</div>
						<div className="mt-20 lg:mt-0 flex flex-col sm:flex-row items-center">
							{/* <Button
								primary={true}
								small={true}
								className="mr-16 whitespace-nowrap"
								label="Learn more"
								onClick={() => {
									window.umami && window.umami.track("lrds-banner-learnmore")
									window
										.open("https://www.lr.org/en/digital-solutions/", "_blank")
										.focus()
								}}
							/> */}

							<span>
								<TextButton
									light={true}
									className="ml-16 mt-20 sm:mt-0 whitespace-nowrap"
									onClick={() => {
										window.umami && window.umami.track("lrds-banner-dismiss")
										setShowBanner(false)
									}}>
									Dismiss
								</TextButton>
							</span>
						</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	)
}

export default BottomBanner
