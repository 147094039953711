import React from "react"

import { Link } from "gatsby"

export const Button = ({ primary, className, label, small = false, children, ...props }) => {
	const background = primary ? "bg-pictonblue" : "bg-[#FF8F00]"

	const hover = primary ? "hover:bg-tealblue" : "hover:bg-solaris"

	const focus = primary
		? "focus:bg-tealblue focus:ring-4 focus:ring-inset focus:ring-[#214866]"
		: "focus:bg-solaris focus:ring-4 focus:ring-inset focus:ring-[#FF8F00]"

	const disabled = primary
		? "disabled:bg-silversand disabled:ring-silversand"
		: "disabled:bg-silversand disabled:ring-silversand"
	return (
		<span
			className={`inline-flex flex-row items-center justify-center transition-color duration-300 ease-in-out text-white captionBtn text-center ${
				small ? "pt-[11px] pb-[10px] px-20" : "py-[14px] px-40"
			} rounded-full ${background} hover:cursor-pointer ${hover} ${focus} ${disabled} ${
				className || ""
			}`}
			{...props}>
			{label || children}
		</span>
	)
}

export const FormButton = ({ primary, type = "button", className, label, children, ...props }) => {
	const background = primary ? "bg-pictonblue" : "bg-[#FF8F00]"

	const hover = primary ? "hover:bg-tealblue" : "hover:bg-solaris"

	const focus = primary
		? "focus:bg-tealblue focus:ring-4 focus:ring-inset focus:ring-[#214866]"
		: "focus:bg-solaris focus:ring-4 focus:ring-inset focus:ring-[#FF8F00]"

	const disabled = primary
		? "disabled:bg-silversand disabled:ring-silversand"
		: "disabled:bg-silversand disabled:ring-silversand"
	return (
		<button
			type={type}
			className={`transition-color duration-300 ease-in-out text-white captionBtn py-[14px] px-40 rounded-full ${background} ${hover} ${focus} ${disabled} ${
				className || ""
			}`}
			{...props}>
			{label || children}
		</button>
	)
}

export const TextButton = ({ className = "", children, light, ...props }) => {
	const textColor = light
		? "text-white hover:text-white hover:decoration-white"
		: "text-pictonblue hover:text-tealblue hover:decoration-tealblue"

	return (
		<span
			className={`uppercase font-bold text-sm leading-18 tracking-wider text-center underline decoration-2 underline-offset-[5px] transition-all hover:cursor-pointer ${textColor} ${className}`}
			{...props}>
			{children}
		</span>
	)
}

export const TextLink = ({
	className = "",
	to,
	children,
	light,
	download = false,
	Icon = null,
	target = "_self",
	...other
}) => {
	const textColor = light
		? "text-white hover:text-white hover:decoration-white"
		: "text-pictonblue hover:text-tealblue hover:decoration-tealblue"
	return (
		<span className="flex flex-row items-end">
			<a
				href={to}
				target={target}
				download={download}
				className={`transition-all hover:cursor-pointer  ${textColor} ${className}`}
				activeClassName=""
				{...other}>
				{Icon && <Icon className={`h-[18px] mr-8 mt-2`} />}
				<span
					className={`uppercase font-bold text-sm leading-18 tracking-wider underline decoration-2 underline-offset-[5px]`}>
					{children}
				</span>
			</a>
		</span>
	)
}
